<template>
  <div class="coverage">
    <vs-row vs-type="flex" class="justify-between pt-0">
      <h4 class="font-bold">Coberturas Adicionais</h4>

      <vs-button @click="openModalCoverage(coverageDefault)">
        Adicionar Cobertura
      </vs-button>
    </vs-row>

    <div class="header">
      <div class="pt-4 mt-5 flex flex-row-reverse w-full"></div>
    </div>
    <div class="coverage-list">
      <template v-if="(coverageParams || []).length > 0">
        <vs-table :data="coverageParams || []">
          <template slot="thead">
            <vs-th> Cobertura </vs-th>
            <vs-th> Data de cadastro </vs-th>
            <vs-th> Código de referência </vs-th>
            <vs-th> Tipo de Cálculo da Cobertura </vs-th>
            <vs-th> Valor do Cálculo da Cobertura </vs-th>
            <vs-th> Status </vs-th>
            <vs-th> Ações </vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].Name">
                {{ data[indextr].Name }}
              </vs-td>

              <vs-td :data="data[indextr].CreateDate">
                {{ data[indextr].CreateDate | moment("DD/MM/YYYY") }}
              </vs-td>

              <vs-td :data="data[indextr].Ref">
                {{ data[indextr].Ref }}
              </vs-td>

              <vs-td :data="data[indextr].CoverageCalculationJSONParam">
                {{ data[indextr].calculationCoverage.name }}
              </vs-td>
              <vs-td :data="data[indextr].CoverageCalculationJSONParam">
                {{ data[indextr].calculationCoverage.value }}
              </vs-td>
              <vs-td>
                {{ data[indextr].IsDeleted ? "Inativa" : "Ativa" }}
              </vs-td>
              <vs-td>
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="cursor-pointer"
                >
                  <vs-button color="dark" type="flat" title="Ações">
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>
                  <vs-dropdown-menu
                    style="width: 200px; z-index: 53001 !important"
                  >
                    <vs-dropdown-item
                      @click="openModalCoverage(data[indextr])"
                    >
                      <p class="text-base px-6 py-2">
                        <span>Editar</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="
                        openModalDelete(
                          data[indextr].Name,
                          data[indextr].Id,
                          data[indextr].IsDeleted
                        )
                      "
                    >
                      <p class="text-base px-6 py-2">
                        <span>Inativar/Ativar</span>
                      </p>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
    </div>
    <vs-popup
      title="Cobertura Adicional"
      class="popup-aditional-coverage"
      :active.sync="modalCoverage"
    >
      <div>
        <horizontal-tabs-components :tabs="tabs">
          <template slot="tab-content-0">
            <vs-row>
              <vs-col class="w-1/2">
                <vs-input
                  class="w-full"
                  label="Nome da cobertura"
                  v-model="coverageObject.Name"
                />
              </vs-col>

              <vs-col class="w-1/2">
                <vs-input
                  class="w-full"
                  label="Código de referência"
                  v-model="coverageObject.Ref"
                />
              </vs-col>
            </vs-row>

            <vs-row>
              <vs-col class="w-1/2">
                <p>Tipo de cálculo</p>
                <v-select
                  v-if="coverageObject.calculationCoverage"
                  v-model="coverageObject.calculationCoverage.name"
                  :options="['AggravationRate', 'DistributionRate']"
                  placeholder=""
                  class="w-full"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
              </vs-col>

              <vs-col class="w-1/2">
                <vs-input
                  v-if="coverageObject.calculationCoverage"
                  class="w-full"
                  v-model="coverageObject.calculationCoverage.value"
                  label="Valor do cálculo"
                />
              </vs-col>
            </vs-row>

            <vs-row>
              <vs-col vs-type="flex" vs-justify="end">
                <el-button
                  type="primary"
                  @click="saveCoverage(coverageObject)"
                  :loading="loadingButton"
                  >Salvar</el-button
                >
              </vs-col>
            </vs-row>
          </template>

          <template slot="tab-content-1">
            <vs-row>
              <vs-col class="md:w-5/12 w-full">
                <vs-input
                  class="w-full"
                  label="Referência da seguradora"
                  v-model="object.RefIntegration"
                />
              </vs-col>
            </vs-row>

            <vs-row v-for="(item, index) in object.Tags" :key="index">
              <vs-col class="md:w-5/12 w-full">
                <vs-input
                  class="w-full"
                  label="Descrição"
                  v-model="item.Label"
                />
              </vs-col>

              <vs-col class="md:w-6/12 w-full pb-5">
                <vs-input
                  class="w-full"
                  label="Tag"
                  :value="item.Name"
                  @change="setName(item, $event.target.value, 5)"
                />
              </vs-col>

              <vs-col class="md:w-1/12 w-full m-auto">
                <vs-button
                  type="flat"
                  icon="close"
                  size="large"
                  @click="removeTag(item, index, 5)"
                ></vs-button>
              </vs-col>
            </vs-row>

            <vs-row>
              <vs-col vs-type="flex" vs-justify="end">
                <vs-button
                  type="border"
                  icon="add"
                  :icon-after="true"
                  @click="addTag(5)"
                  class="add-tag py-1"
                >
                  Incluir TAG
                </vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-5">
              <vs-switch v-model="object.EnableToProposal"></vs-switch>
              <label class="ml-2"> Habilitar na Proposta</label>
            </vs-row>
            <vs-row>
              <vs-col>
                <jodit-editor
                  :config="joditConfig"
                  id="objeto"
                  v-model="object.Value"
                />
              </vs-col>
            </vs-row>

            <vs-row>
              <vs-col vs-type="flex" vs-justify="end">
                <el-button
                  type="primary"
                  @click="saveObject(5)"
                  :loading="loadingButton"
                  >Salvar objeto</el-button
                >
              </vs-col>
            </vs-row>
          </template>
          <template slot="tab-content-2">
            <vs-row v-for="(item, index) in condicaoGeral.Tags" :key="index">
              <vs-col class="md:w-5/12 w-full">
                <vs-input
                  class="w-full"
                  label="Descrição"
                  v-model="item.Label"
                />
              </vs-col>

              <vs-col class="md:w-6/12 w-full pb-5">
                <vs-input
                  class="w-full"
                  label="Tag"
                  :value="item.Name"
                  @change="setName(item, $event.target.value, 1)"
                />
              </vs-col>

              <vs-col class="md:w-1/12 w-full m-auto">
                <vs-button
                  type="flat"
                  icon="close"
                  size="large"
                  @click.native="removeTag(item, index, 1)"
                ></vs-button>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col class="md:w-5/12 w-full">
                <vs-input
                  class="w-full"
                  label="Referência da seguradora"
                  v-model="condicaoGeral.RefIntegration"
                />
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="end">
                <vs-button
                  type="border"
                  icon="add"
                  class="add-tag py-1"
                  :icon-after="true"
                  @click="addTag(1)"
                >
                  Incluir TAG
                </vs-button>
              </vs-col>
            </vs-row>

            <vs-row class="mb-5">
              <vs-switch v-model="condicaoGeral.EnableToProposal"></vs-switch>
              <label class="ml-2"> Habilitar na Proposta</label>
            </vs-row>
            <vs-row>
              <vs-col>
                <jodit-editor
                  id="condicoes"
                  :config="joditConfig"
                  v-model="condicaoGeral.Value"
                />
              </vs-col>
            </vs-row>

            <vs-row>
              <vs-col vs-type="flex" vs-justify="end">
                <el-button
                  type="primary"
                  @click="saveObject(7)"
                  :loading="loadingButton"
                  >Salvar Condições Gerais</el-button
                >
              </vs-col>
            </vs-row>
          </template>
          <template slot="tab-content-3">
            <vs-row v-for="(item, index) in condition.Tags" :key="index">
              <vs-col class="md:w-5/12 w-full">
                <vs-input
                  class="w-full"
                  label="Descrição"
                  v-model="item.Label"
                />
              </vs-col>

              <vs-col class="md:w-6/12 w-full pb-5">
                <vs-input
                  class="w-full"
                  label="Tag"
                  :value="item.Name"
                  @change="setName(item, $event.target.value, 4)"
                />
              </vs-col>

              <vs-col class="md:w-1/12 w-full m-auto">
                <vs-button
                  type="flat"
                  icon="close"
                  size="large"
                  @click.native="removeTag(item, index, 4)"
                ></vs-button>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col class="md:w-5/12 w-full">
                <vs-input
                  class="w-full"
                  label="Referência da seguradora"
                  v-model="condition.RefIntegration"
                />
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="end">
                <vs-button
                  type="border"
                  icon="add"
                  class="add-tag py-1"
                  :icon-after="true"
                  @click="addTag(4)"
                >
                  Incluir TAG
                </vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-5">
              <vs-switch v-model="condition.EnableToProposal"></vs-switch>
              <label class="ml-2"> Habilitar na Proposta</label>
            </vs-row>
            <vs-row>
              <vs-col>
                <jodit-editor
                  id="condicoes"
                  :config="joditConfig"
                  v-model="condition.Value"
                />
              </vs-col>
            </vs-row>

            <vs-row>
              <vs-col vs-type="flex" vs-justify="end">
                <el-button
                  type="primary"
                  @click="saveObject(4)"
                  :loading="loadingButton"
                  >Salvar Condições Especiais</el-button
                >
              </vs-col>
            </vs-row>
          </template>
        </horizontal-tabs-components>
      </div>
    </vs-popup>

    <vs-popup
      class="validacao-data-vigente-modal"
      title=""
      :active.sync="modalDelete"
    >
      <vs-row>
        <vs-col class="flex justify-center mb-10">
          <h4 class="text-center">
            Deseja realmente {{ coverageIsDeleted ? "ativar" : "inativar" }} a
            cobertura <b>{{ coverageName }}</b
            >?
          </h4>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col class="text-right">
          <vs-button @click="cancelDelete()" type="border">
            Cancelar
          </vs-button>

          <vs-button
            @click="confirmDelete(coverageId)"
            class="ml-3 button-primary"
          >
            Confirmar
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import HorizontalTabsComponents from "../../../../components/horizontal-tabs/HorizontalTabsComponents.vue";
import { VueEditor, Quill } from "vue2-editor";
import { Jodit, JoditEditor } from "jodit-vue";
const Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block, true);

export default {
  components: {
    "v-select": vSelect,
    VueEditor,
    HorizontalTabsComponents
  },
  props: {
    coverage: Array
  },
  data() {
    return {
      joditConfig: { language: "pt_br" },
      loadingButton: false,
      modalCoverage: false,
      coverageObject: Object,
      CoverageCalculationJSONParam: Object,
      coverageDefault: {
        CoverageCalculationJSONParam: "",
        CoverageCalculationTypeId: "",
        IsDeleted: false,
        IsAdditionalCoverage: true,
        CoverageCalculationTypeId: 0,
        modalityId: this.$route.params.modalidadeId,
        Name: "",
        Ref: "",
        calculationCoverage: {
          name: "",
          value: ""
        }
      },
      coverageParams: [],
      focusEditor: false,
      coberturaTypes: [7, 4, 5],
      object: {},
      condition: {},
      condicaoGeral: {},
      modalDelete: false,
      coverageId: null,
      coverageName: null,
      coverageIsDeleted: null,
      selectedTab: 1
    };
  },
  computed: {
    tabs() {
      if (this.coverageObject.Id)
        return [
          {
            name: "Detalhe da cobertura",
            index: 0
          },
          {
            name: "Objeto Cobertura",
            index: 1
          },
          {
            name: "Condições Gerais",
            index: 2
          },
          {
            name: "Condições especiais",
            index: 3
          }
        ];
      else
        return [
          {
            name: "Detalhe da cobertura",
            index: 0
          }
        ];
    }
  },
  mounted() {
    this.getCoverages();
  },
  created() {
    this.setupEditor();
  },
  methods: {
    ...mapActions("seguradora-module", [
      "getModalityObject",
      "saveModalityCoverage",
      "UpdateCoverage",
      "enableDisableModalityCoverage",
      "getAdditionalCoverages",
      "saveModalityObject",
      "updateModalityCoverage"
    ]),

    setupEditor() {
      Jodit.defaultOptions.controls.font.list = {
        "Arial Narrow": "Arial Narrow",
        Calibri: "Calibri",
        "Arial,Helvetica,sans-serif": "Arial",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
        "Roboto Medium,Arial,sans-serif": "Roboto"
      };
      Jodit.defaultOptions.controls.fontsize.list = {
        12.5: "12.5",
        15: "15",
        16: "16",
        18: "18",
        19: "19",
        24: "24",
        24: "24",
        36: "36",
        48: "48",
        56: "56",
        70: "70",
        90: "90"
      };
    },
    openModalDelete(coverageName, coverageId, isDeleted) {
      this.modalDelete = true;
      this.coverageName = coverageName;
      this.coverageId = coverageId;
      this.coverageIsDeleted = isDeleted;
    },
    cancelDelete() {
      this.modalDelete = false;
      this.coverageName = "";
      this.coverageId = null;
      this.coverageIsDeleted = null;
    },
    confirmDelete(coverageId) {
      this.deleteCoverage(coverageId);
    },
    async openModalCoverage(coverageObject) {
      this.coverageObject = this.$utils.deepClone(coverageObject);
      this.$forceUpdate();
      this.modalCoverage = true;
      if (coverageObject.Id) {
        this.condition = {};
        this.object = {};
        await this.getModality(coverageObject.Id);
      }
    },
    async saveCoverage(coverage) {
      this.$vs.loading();
      this.loadingButton = true;
      await this.$onpoint.loading(async() => {
        const CoverageCalculationTypeId =
          this.coverageObject.calculationCoverage.name === "AggravationRate"
            ? 1
            : 2;
        this.coverageObject.RefIntegration = this.coverageObject.Ref;
        if (!coverage.Id) {
          return await this.saveModalityCoverage({
            ...coverage,
            CoverageCalculationJSONParam: `{\"${this.coverageObject.calculationCoverage.name}\"  : ${this.coverageObject.calculationCoverage.value}}`,
            CoverageCalculationTypeId
          })
            .then(resp => {
              this.loadingButton = false;
              this.$vs.loading.close();
              this.modalCoverage = false;
              this.coverageObject = {};
              this.updateCoverageList();
              this.modalCoverage = false;
              this.$onpoint.successModal("Cobertura adicionada com sucesso.");
              this.$forceUpdate();
            })
            .catch(ex => {
              this.loadingButton = false;
              this.$vs.loading.close();
              this.$onpoint.errorModal(
                ex.response.data.Errors.length
                  ? ex.response.data.Errors
                  : ex.response.data.Response
              );
            });
        } else {
          return await this.updateModalityCoverage({
            ...coverage,
            CoverageCalculationJSONParam: `{\"${this.coverageObject.calculationCoverage.name}\" : ${this.coverageObject.calculationCoverage.value}}`,
            CoverageCalculationTypeId
          })
            .then(resp => {
              this.loadingButton = false;
              this.$vs.loading.close();
              this.modalCoverage = false;
              this.coverageObject = {};
              this.updateCoverageList();
              this.$onpoint.successModal("Cobertura adicionada com sucesso.");
              this.$forceUpdate();
              this.modalCoverage = false;
            })
            .catch(ex => {
              this.loadingButton = false;
              this.$vs.loading.close();
              this.$onpoint.errorModal(
                ex.response.data.Errors.length
                  ? ex.response.data.Errors
                  : ex.response.data.Response
              );
            });
        }
      });
    },
    async getModality(coverageId) {
     this.object.Value = "";
     this.condition.Value = "";
     this.condicaoGeral.Value = "";

     await this.coberturaTypes.forEach( async type => {
       await this.$onpoint.loading(async () => {
          return await this.getModalityObject({
            modalityId: coverageId,
            termType: type
          }).then(resp => {
            if (resp.RefIntegration) {
              this.coverageObject.RefIntegration = resp.RefIntegration;
            }
            if (resp) {
              if (type === 5) {
                this.object = resp;
              } else if (type === 4) {
                this.condition = resp;
              } else if (type === 7) {
                this.condicaoGeral = resp;
              }
            }
          });
        });
      });
    },
    getCoverages() {
      this.coverageParams = this.coverage
        .filter(x => x.CoverageCalculationJSONParam)
        .map(coverage => {
          const coverageCalculation = JSON.parse(
            coverage.CoverageCalculationJSONParam
          );
          const coverageCalculationTypeId = coverage.CoverageCalculationTypeId;
          return {
            ...coverage,
            calculationCoverage: {
              name:
                coverageCalculationTypeId == 1
                  ? "AggravationRate"
                  : "DistributionRate",
              value: coverageCalculation[Object.keys(coverageCalculation)[0]]
            }
          };
        });
    },
    async deleteCoverage(coverageId) {
      await this.$onpoint.loading(async() => {
        return await  this.enableDisableModalityCoverage(coverageId)
          .then(resp => {
            this.updateCoverageList();
            this.$onpoint.successModal(
              `Cobertura ${
                this.coverageIsDeleted ? "inativada" : "ativada"
              } com sucesso.`
            );
          })
          .catch(ex => {
            this.$onpoint.errorModal(
              ex.response.data.Errors.length
                ? ex.response.data.Errors
                : ex.response.data.Response
            );
          })
          .finally(() => {
            this.modalDelete = false;
            this.coverageName = "";
            this.coverageId = null;
            this.coverageIsDeleted = null;
            this.$forceUpdate();
          });
      });
    },
    addTag(isCondicoes) {
      if (isCondicoes === 4) {
        if (!this.condition.Tags) this.condition.Tags = [];

        let tag = {
          Label: `Nova tag ${this.condition.Tags.length}`,
          Name: `NOVA_TAG_${this.condition.Tags.length}`
        };
        this.condition.Tags.push(tag);
        this.condition.Value += `[${tag.Name}]`;
      } else if (isCondicoes === 5) {
        if (!this.object.Tags) this.object.Tags = [];

        let tag = {
          Label: `Nova tag ${this.object.Tags.length}`,
          Name: `NOVA_TAG_${this.object.Tags.length}`
        };
        this.object.Tags.push(tag);
        this.object.Value = (this.object.Value || "") + `[${tag.Name}]`;
      } else if (isCondicoes === 7) {
        if (!this.condicaoGeral.Tags) this.condicaoGeral.Tags = [];

        let tag = {
          Label: `Nova tag ${this.condicaoGeral.Tags.length}`,
          Name: `NOVA_TAG_${this.condicaoGeral.Tags.length}`
        };
        this.condicaoGeral.Tags.push(tag);
        this.condicaoGeral.Value =
          (this.condicaoGeral.Value || "") + `[${tag.Name}]`;
      }

      this.$forceUpdate();
    },
    setName(item, value, isCondicoes) {
      value = value.replace(" ", "_").toUpperCase();
      if (isCondicoes === 4) {
        if (this.condition.Value.includes(`[${value}]`)) return;

        this.condition.Value = this.condition.Value.replace(item.Name, value);
        item.Name = value;
      }
      if (isCondicoes === 5) {
        if (this.object.Value.includes(`[${value}]`)) return;

        this.object.Value = this.object.Value.replace(item.Name, value);
        item.Name = value;
      }
      if (isCondicoes === 7) {
        if (this.condicaoGeral.Value.includes(`[${value}]`)) return;
        this.condicaoGeral.Value = this.condicaoGeral.Value.replace(
          item.Name,
          value
        );
        item.Name = value;
      }
      this.$forceUpdate();
    },
    removeTag(item, index, isCondicoes) {
      if (isCondicoes === 4) {
        this.condition.Value = this.condition.Value.replace(
          `[${item.Name}]`,
          ""
        );
        this.condition.Tags.splice(index, 1);
      } else if (isCondicoes === 5) {
        this.object.Value = this.object.Value.replace(`[${item.Name}]`, "");
        this.object.Tags.splice(index, 1);
      } else if (isCondicoes === 1) {
        this.condicaoGeral.Value = this.condicaoGeral.Value.replace(
          `[${item.Name}]`,
          ""
        );
        this.condicaoGeral.Tags.splice(index, 1);
      }
      this.$forceUpdate();
    },
    async saveObject(isCondicoes) {
      this.loadingButton = true;
      let data = {};
      if (isCondicoes === 4) {
        data = {
          RefIntegration: this.condition.RefIntegration,
          Value: this.condition.Value,
          JSONTag:
            this.condition.Tags && this.condition.Tags.length
              ? JSON.stringify(this.condition.Tags)
              : "",
          ContractualTermTypeId: 4,
          EnableToProposal: this.condition.EnableToProposal
        };
      } else if (isCondicoes === 5) {
        data = {
          RefIntegration: this.object.RefIntegration,
          Value: this.object.Value,
          JSONTag:
            this.object.Tags && this.object.Tags.length
              ? JSON.stringify(this.object.Tags)
              : "",
          ContractualTermTypeId: 5,
          EnableToProposal: this.object.EnableToProposal
        };
      } else if (isCondicoes === 7) {
        data = {
          RefIntegration: this.condicaoGeral.RefIntegration,
          Value: this.condicaoGeral.Value,
          JSONTag:
            this.condicaoGeral.Tags && this.condicaoGeral.Tags.length
              ? JSON.stringify(this.condicaoGeral.Tags)
              : "",
          ContractualTermTypeId: 7,
          EnableToProposal: this.condicaoGeral.EnableToProposal
        };
      }
      this.$vs.loading();
      await this.$onpoint.loading(async () => {
        return await this.saveModalityObject({
          ...data,
          Ref1Id: this.coverageObject.Id
        })
          .then(resp => {
            this.loadingButton = false;
            this.$vs.loading.close();
            if (isCondicoes) this.condition = resp;
            else this.object = resp;

            this.updateCoverageList();
            this.$forceUpdate();
            this.$onpoint.successModal("Dados inseridos com sucesso.");
            this.modalCoverage = false;
          })
          .catch(ex => {
            this.loadingButton = false;
            this.$vs.loading.close();
            this.$onpoint.errorModal(
              ex.response.data.Errors.length
                ? ex.response.data.Errors
                : ex.response.data.Response
            );
          });
      });
    },
    async updateCoverageList() {
      await this.$onpoint.loading(async () => {
        return await this.getAdditionalCoverages({
          uniqueId: this.$route.params.modalidadeUniqueId,
          ignoreDeleted: false
        }).then(coverages => {
          this.coverage = coverages;
          this.getCoverages();
        });
      });
    }
  }
};
</script>

<style lang="scss">
.coverage-list {
  .vs-con-table .vs-con-tbody {
    background: none;
  }

  .vs-con-table .vs-con-tbody {
    border: none;
  }
}

.success-modal,
.error-modal {
  z-index: 60000;
}
.popup-aditional-coverage {
  .vs-popup {
    width: 900px !important;
    height: 90%;

    .vs__dropdown-toggle {
      height: 38px !important;

      .vs__selected {
        padding: 0.2rem 0.5rem 0.5rem !important;
      }
    }

    .add-tag i {
      font-size: 2rem;
    }
  }
}
#objeto,
#condicoes {
  height: 450px;
}
</style>

<style lang="scss" scoped>
.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>
