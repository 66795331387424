<template>
  <div id="contractual-term-container">
    <div class="vx-row" v-for="(item, index) in jsonTags" :key="index">
      <div class="vx-col md:w-4/12 w-full">
        <vs-input
          class="w-full"
          size="large"
          label="Label"
          :value="item.Label"
          @input="setInputTag({ Label: $event }, item)"
          @change="setName(item, $event.target.value)"
        />
      </div>

      <div class="vx-col md:w-3/12 w-full pb-5">
        <vs-input
          class="w-full"
          size="large"
          label="Tag"
          :value="item.Name"
          @input="setInputTag({ Name: $event }, item)"
        />
      </div>

      <div class="vx-col md:w-3/12 w-full pb-5">
        <label class="vs-input--label">Selecione o tipo</label>
        <v-select
          label="name"
          size="large"
          placeholder="Tipo"
          class="w-full"
          :options="options"
          :value="item.Type || 'Text'"
          :reduce="option => option.value"
          @input="setInputTag({ Type: $event }, item)"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
      </div>

      <div class="vx-col md:w-2/12 w-full m-auto">
        <vs-row>
          <vs-tooltip text="Campos do Repeater">
            <vs-button
              v-if="item.Type == 'Repeater'"
              class="vs-col md:w-1/12 w-full m-auto"
              type="flat"
              icon="view_agenda"
              size="large"
              @click.native="openRepeaterFields(item)"
            ></vs-button>
          </vs-tooltip>
          <vs-button
            class="vs-col md:w-1/12 w-full m-auto"
            type="flat"
            icon="close"
            size="large"
            @click.native="removeTag(item)"
          ></vs-button>
        </vs-row>
      </div>
    </div>

    <div class="p-4 mt-5 flex flex-row-reverse w-full">
      <vs-button
        type="flat"
        icon="add_circle"
        class="px-1"
        :icon-after="true"
        @click="addTag()"
      >
        Incluir TAG</vs-button
      >
    </div>

    <vs-row class="mb-5">
      <vs-col vs-w="12">
        <h4>TAGs de preenchimento automático</h4>
      </vs-col>

      <vs-col
        v-for="(item, index) in tagsAutomatic"
        class="mt-3"
        vs-lg="6"
        vs-sm="12"
        :key="`tag-name-${index}`"
      >
        <label class="count-container">
          <vs-input-number
            v-model.number="autoTagCoutList[item.Name]"
            @input="setAutoTag(item)"
            class="pr-3"
          />
          {{ item["Label"] }}
        </label>
      </vs-col>
    </vs-row>
    <jodit-editor
      id="term-quill"
      ref="term-quill"
      v-model="value"
      :config="joditConfig"
      read-only="true"
    />

    <app-dialog
      :title="
        `Repeater ${
          (repeater || {}).selected
            ? ((repeater || {}).selected || {}).Name
            : ''
        }`
      "
      class="dialog-repeater-fields"
      :fullscreen="true"
      @cancel="cancelDialogRepeater"
      @confirm="confirmDialogRepeater"
      v-model="repeater.dialog"
    >
      <template slot="content">
        <div>
          <repeater-tags
            v-if="repeater.selected"
            :contractual-term="contractualTerm"
            :tag="repeater.selected"
          ></repeater-tags>
        </div>
      </template>
    </app-dialog>
  </div>
</template>

<script>
import { Jodit, JoditEditor } from "jodit-vue";
import RepeaterTags from "./RepeaterTags";
import vSelect from "vue-select";
import { VueEditor, Quill } from "vue2-editor";
const Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block, true);

export default {
  components: { VueEditor, "v-select": vSelect, RepeaterTags },
  props: {
    allowedTextArea: { type: Boolean, default: false },
    allowedRepeater: { type: Boolean, default: false },
    jsonTagsAll: { type: Array, default: () => [] },
    contractualTerm: { type: Object, default: () => {} },
    valueTerm: { type: String, default: "" }
  },
  data() {
    return {
      joditConfig: { language: "pt_br" },
      repeater: {
        dialog: false,
        selected: null
      },
      repeaterFieldsDialog: false,
      repeaterTagSelected: null,
      focusEditor: false,
      autoTagCoutList: {},
      customToolbar: {
        toolbar: [
          ["Undo", "Redo"],
          ["HorizontalRule"],
          ["Styles", "Format", "Font", "FontSize"],
          ["Bold", "Italic"],
          ["Undo", "Redo", "Underline", "Strike", "Subscript", "Superscript"],
          ["About"]
        ]
      }
    };
  },
  computed: {
    options() {
      let options = [
        {
          value: "Date",
          name: "Data"
        },
        {
          value: "Number",
          name: "Número"
        },
        {
          value: "Text",
          name: "Texto"
        }
      ];

      if (this.allowedTextArea)
        options.push({
          value: "TextArea",
          name: "Area de Texto"
        });

      if (this.allowedRepeater)
        options.push({
          value: "Repeater",
          name: "Repeater"
        });

      return options;
    },

    tagsAutoValues() {
      let values = {};

      this.jsonTagsAll
        .filter(x => x.Type == "Automatic")
        .forEach(item => {
          values[item.Name] = true;
        });

      return values;
    },

    tagsAutomatic() {
      return [
        {
          Name: "INI_VIGENCIA",
          Label: "Inicio da Vigência",
          Type: "Automatic"
        },
        {
          Name: "FIM_VIGENCIA",
          Label: "Fim da Vigência",
          Type: "Automatic"
        },
        {
          Name: "VALOR_IS",
          Label: "Valor IS",
          Type: "Automatic"
        },
        {
          Name: "VALOR_IS_EXT",
          Label: "Valor IS por Extenso",
          Type: "Automatic"
        },
        {
          Name: "PRAZO_DIAS_EXT",
          Label: "Prazo em Dias por Extenso",
          Type: "Automatic"
        },
        {
          Name: "PRAZO_DIAS",
          Label: "Prazo em Dias",
          Type: "Automatic"
        },
        {
          Name: "INI_VIGENCIA_COMPL",
          Label: "Inicio da Vigência Complementar",
          Type: "Automatic"
        },
        {
          Name: "FIM_VIGENCIA_COMPL",
          Label: "Fim da Vigência Complementar",
          Type: "Automatic"
        },
        {
          Name: "VALOR_IS_COMPL",
          Label: "Valor IS Complementar",
          Type: "Automatic"
        },
        {
          Name: "VALOR_IS_COMPL_EXT",
          Label: "Valor IS Complementar por Extenso",
          Type: "Automatic"
        },
        {
          Name: "PRAZO_DIAS_COMPL_EXT",
          Label: "Prazo em Dias Complementar por Extenso",
          Type: "Automatic"
        },
        {
          Name: "PRAZO_DIAS_COMPL",
          Label: "Prazo em Dias Complementar",
          Type: "Automatic"
        },
        {
          Name: "TOMADOR",
          Label: "Tomador",
          Type: "Automatic"
        },
        {
          Name: "CNPJ_TOMADOR",
          Label: "Cnpj Tomador",
          Type: "Automatic"
        },
        {
          Name: "ENDERECO_COMPLETO_TOMADOR",
          Label: "Endereco Completo Tomador",
          Type: "Automatic"
        },
        {
          Name: "ENDERECO_TOMADOR",
          Label: "Endereco Tomador",
          Type: "Automatic"
        },
        {
          Name: "CIDADE_TOMADOR",
          Label: "Cidade Tomador",
          Type: "Automatic"
        },
        {
          Name: "BAIRRO_TOMADOR",
          Label: "Bairro Tomador",
          Type: "Automatic"
        },
        {
          Name: "ESTADO_TOMADOR",
          Label: "Estado Tomador",
          Type: "Automatic"
        },
        {
          Name: "NRO_TOMADOR",
          Label: "Nroº Tomador",
          Type: "Automatic"
        },
        {
          Name: "CEP_TOMADOR",
          Label: "Cep Tomador",
          Type: "Automatic"
        },
        {
          Name: "SEGURADO",
          Label: "Segurado",
          Type: "Automatic"
        },
        {
          Name: "CPF_CNPJ_SEGURADO",
          Label: "Cnpj Segurado",
          Type: "Automatic"
        },
        {
          Name: "ENDERECO_COMPLETO_SEGURADO",
          Label: "Endereco Completo Segurado",
          Type: "Automatic"
        },
        {
          Name: "ENDERECO_SEGURADO",
          Label: "Endereco Segurado",
          Type: "Automatic"
        },
        {
          Name: "BAIRRO_SEGURADO",
          Label: "Bairro Segurado",
          Type: "Automatic"
        },
        {
          Name: "CIDADE_SEGURADO",
          Label: "Cidade Segurado",
          Type: "Automatic"
        },
        {
          Name: "ESTADO_SEGURADO",
          Label: "Estado Segurado",
          Type: "Automatic"
        },
        {
          Name: "NRO_SEGURADO",
          Label: "Nroº Segurado",
          Type: "Automatic"
        },
        {
          Name: "CEP_SEGURADO",
          Label: "CEP Segurado",
          Type: "Automatic"
        },
        {
          Name: "NOME_SEGURADORA",
          Label: "Nome da Seguradora",
          Type: "Automatic"
        },
        {
          Name: "CNPJ_SEGURADORA",
          Label: "Cnpj da Seguradora",
          Type: "Automatic"
        },
        {
          Name: "CIDADE_SEGURADORA",
          Label: "Cidade da Seguradora",
          Type: "Automatic"
        },
        {
          Name: "ESTADO_SEGURADORA",
          Label: "Estado da Seguradora",
          Type: "Automatic"
        },
        {
          Name: "CEP_SEGURADORA",
          Label: "Cep da Seguradora",
          Type: "Automatic"
        },
        {
          Name: "NRO_SEGURADORA",
          Label: "Nro da Seguradora",
          Type: "Automatic"
        },
        {
          Name: "RUA_SEGURADORA",
          Label: "Rua da Seguradora",
          Type: "Automatic"
        },
        {
          Name: "ENDERECO_COMPLETO_SEGURADORA",
          Label: "Endereço Completo da Seguradora",
          Type: "Automatic"
        },
        {
          Name: "BAIRRO_SEGURADORA",
          Label: "Bairro da Seguradora",
          Type: "Automatic"
        }
      ];
    },

    jsonTags: {
      get() {
        return this.jsonTagsAll && this.jsonTagsAll.length
          ? (this.jsonTagsAll || []).filter(x => x.Type != "Automatic") || []
          : [];
      },

      set(value) {
        this.$emit("setJSONTagsAll", [
          ...value,
          ...(this.jsonTagsAll || []).filter(x => x.Type == "Automatic")
        ]);
      }
    },

    value: {
      get() {
        return this.valueTerm;
      },

      set(value) {
        this.$emit("setValueTerm", value);
      }
    }
  },
  created() {
    this.setupEditor();
  },
  methods: {
    setupEditor() {
      Jodit.defaultOptions.controls.font.list = {
        Calibri: "Calibri",
        "Arial Narrow": "Arial Narrow",
        "Arial,Helvetica,sans-serif": "Arial",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
        "Roboto Medium,Arial,sans-serif": "Roboto"
      };

      Jodit.defaultOptions.controls.fontsize.list = {
        12.5: "12.5",
        15: "15",
        16: "16",
        18: "18",
        19: "19",
        24: "24",
        24: "24",
        36: "36",
        48: "48",
        56: "56",
        70: "70",
        90: "90"
      };
    },
    openRepeaterFields(repeater) {
      this.repeater.selected = Object.assign({}, repeater);
      this.repeater.dialog = true;
    },

    confirmDialogRepeater() {
      if (!this.repeater.selected) return;
      let tag = this.jsonTagsAll.find(
        tag => tag.Name == this.repeater.selected.Name
      );
      if (tag) {
        const index = this.jsonTagsAll.indexOf(tag);
        this.jsonTagsAll[index] = this.repeater.selected;
      }

      this.repeater.dialog = false;
      this.repeater.selected = null;
    },

    cancelDialogRepeater() {
      this.repeater.dialog = false;
      this.repeater.selected = null;
    },

    setInputTag(value, tag) {
      let tags = this.jsonTagsAll.map(x =>
        x != tag ? x : { ...tag, ...value }
      );
      if (value.Type == "Repeater")
        this.openRepeaterFields({ ...tag, ...value });

      this.$emit("setJSONTagsAll", tags);
    },

    setAutoTag(item) {
      if (this.autoTagCoutList[item.Name] > this.countTagAutomatic(item.Name)) {
        if (!this.validTagAdd(item))
          this.$emit("setJSONTagsAll", [...this.jsonTagsAll, item]);

        this.value += `[${item.Name}]`;
      } else if (
        this.autoTagCoutList[item.Name] < this.countTagAutomatic(item.Name)
      ) {
        this.value = this.value.replace(`[${item.Name}]`, "");

        if (!this.countTagAutomatic(item.Name)) {
          let index = this.jsonTagsAll.indexOf(item);
          this.jsonTagsAll.splice(index, 1);
        }
      }
    },

    validTagAdd(item) {
      return this.jsonTagsAll.indexOf(item) > 0;
    },

    addTag() {
      if (!this.jsonTagsAll) this.jsonTagsAll = [];

      let tag = {
        Label: `Nova tag ${this.jsonTagsAll.length}`,
        Name: `NOVA_TAG_${this.jsonTagsAll.length}`
      };

      this.$emit("setJSONTagsAll", [...this.jsonTagsAll, tag]);
      this.value += `[${tag.Name}]`;
    },

    removeTag(item) {
      let index = this.jsonTagsAll.indexOf(
        this.jsonTagsAll.find(x => x.Name == item.Name)
      );

      if (index > -1) {
        this.value = this.value.replace(`[${item.Name}]`, "");

        this.$emit(
          "setJSONTagsAll",
          this.jsonTagsAll.filter(x => x.Name !== item.Name)
        );

        this.$forceUpdate();
      }
    },

    setName(item, value) {
      value = `TAG_${value.replace(" ", "_").toUpperCase()}`;

      if (this.value.includes(`[${value}]`)) return;

      this.value = this.value.replace(item.Name, value);

      item.Name = value;

      this.$emit("setJSONTagsAll", this.jsonTagsAll);
      this.$forceUpdate();
    },

    countTagAutomatic(tagName) {
      let tagNameCount = this.value.split(`[${tagName}]`).length;
      return tagNameCount - 1 || 0;
    }
  },
  watch: {
    value(valeu) {
      this.tagsAutomatic.forEach(
        tag =>
          (this.autoTagCoutList[tag.Name] =
            this.countTagAutomatic(tag.Name) || 0)
      );
    }
  },

  mounted() {
    this.tagsAutomatic.forEach(
      tag =>
        (this.autoTagCoutList[tag.Name] = this.countTagAutomatic(tag.Name) || 0)
    );
  }
};
</script>

<style lang="scss">
#contractual-term-container {
  .count-container {
    display: inline-flex;
    align-items: center;
  }
}

.dialog-repeater-fields {
  z-index: 60000;
}

.ql-editor {
  font-size: 12.5px !important;
  font-family: "Calibri" !important;
}

.ql-editor .ql-size-small {
  /* font-size: 0.75em; */
  font-size: 10px;
}

.ql-editor .ql-size-large {
  /* font-size: 1.5em; */
  font-size: 14px;
}

.ql-editor .ql-size-huge {
  /* font-size: 2.5em; */
  font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item::before,
.ql-snow .ql-picker.ql-size .ql-picker-label::before {
  /* content: 'Normal'; */
  content: "12px" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before {
  /* content: 'Small'; */
  content: "10px" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before {
  /* content: 'Large'; */
  content: "14px" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before {
  /* content: 'Huge'; */
  content: "16px" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  /* font-size: 18px; */
  font-size: 14px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  /* font-size: 32px; */
  font-size: 16px;
}
</style>
