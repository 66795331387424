<template>
  <div>
    <div class="vx-row" v-for="(item, index) in tag.Repeater" :key="index">
      <div class="vx-col md:w-4/12 w-full">
        <vs-input
          class="w-full"
          size="large"
          label="Label"
          v-model="item.Label"
          @input="setInputTag({ Label: $event }, item)"
          @change="setName(item, $event.target.value)"
        />
      </div>

      <div class="vx-col md:w-5/12 w-full pb-5">
        <vs-input
          class="w-full"
          size="large"
          label="Tag"
          :value="item.Name"
          @change="setName(item, $event.target.value)"
        />
      </div>

      <div class="vx-col md:w-2/12 w-full pb-5">
        <label class="vs-input--label">Selecione o tipo</label>
        <v-select
          label="name"
          size="large"
          placeholder="Tipo"
          class="w-full"
          :options="options"
          v-model="item.Type"
          @input="selectedType($event, index)"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
      </div>

      <div class="vx-col md:w-1/12 w-full m-auto">
        <vs-row>
          <vs-button
            v-if="item.Type == 'Repeater'"
            class="vs-col md:w-1/12 w-full m-auto"
            type="flat"
            icon="view_agenda"
            size="large"
            @click.native="repeaterFieldsDialog = !repeaterFieldsDialog"
          ></vs-button>
          <vs-button
            type="flat"
            icon="close"
            size="large"
            @click.native="removeTag(item, index)"
          ></vs-button>
        </vs-row>
      </div>
    </div>
    <div class="p-4 mt-5 flex flex-row-reverse w-full">
      <vs-button
        type="flat"
        icon="add_circle"
        class="px-1"
        :icon-after="true"
        @click="addTag()"
      >
        Incluir TAG</vs-button
      >
    </div>
    <jodit-editor  style="width: 100%" :config="joditConfig" id="objeto" v-model="tag.Text" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import { VueEditor, Quill } from "vue2-editor";
import { Jodit, JoditEditor } from "jodit-vue";
const Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block, true);
export default {
  props: {
    contractualTerm: Object,
    tag: Object
  },
  components: { VueEditor },
  data() {
    return {
      joditConfig: { language: "pt_br" },
      focusEditor: false,
      options: [
        {
          value: "Date",
          name: "Data"
        },
        {
          value: "Number",
          name: "Número"
        },
        {
          value: "Text",
          name: "Texto"
        },
        {
          value: "TextArea",
          name: "Area de Texto"
        }
      ],
      customToolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }]
      ]
    };
  },

  computed: {},
  created() {
    this.setupEditor();
  },
  methods: {
    setupEditor() {
      Jodit.defaultOptions.controls.font.list = {
        "Arial Narrow": "Arial Narrow",
        Calibri: "Calibri",
        "Arial,Helvetica,sans-serif": "Arial",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
        "Roboto Medium,Arial,sans-serif": "Roboto"
      };
      Jodit.defaultOptions.controls.fontsize.list = {
        12.5: "12.5",
        15: "15",
        16: "16",
        18: "18",
        19: "19",
        24: "24",
        24: "24",
        36: "36",
        48: "48",
        56: "56",
        70: "70",
        90: "90"
      };
    },
    selectedType(val, index) {
      this.tag.Repeater[index].Type = val.value;
    },
    addTag() {
      if (!this.tag.Repeater) this.tag.Repeater = [];
      if (!this.tag.Text) this.tag.Text = "";
      let tag = {
        Label: `Nova tag ${this.tag.Repeater.length}`,
        Name: `NOVA_TAG_${this.tag.Repeater.length}`
      };
      this.tag.Repeater.push(tag);
      this.tag.Text += `[${tag.Name}]`;
      this.$forceUpdate();
    },
    removeTag(item, index) {
      this.tag.Text = this.tag.Text.replace(`[${item.Name}]`, "");
      this.tag.Repeater.splice(index, 1);
      this.$forceUpdate();
    },

    setName(item, value) {
      value = value.replace(" ", "_").toUpperCase();
      if (this.tag.Text.includes(`[${value}]`)) return;
      this.tag.Text = this.tag.Text.replace(item.Name, value);
      item.Name = value;
      this.$forceUpdate();
    },

    setInputTag(value, tag) {
      let newValue = {
        Label: "TAG_" + value.Label.replace(" ", "_").toUpperCase()
      };
      let tags = this.tag.Repeater.map(x =>
        x != tag ? x : { ...tag, ...newValue }
      );
    }
  }
};
</script>

<style></style>
